import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/ReUsables/SEO";
import {
  MenuItems,
  SEOProperties,
} from "../shared/interfaces/graphql.interface";
import { RespContainer } from "../styles/common";
import { Devices } from "../styles/variables";
import EzReadMore from "../components/ReUsables/EzReadMore";

// export default ({ pageContext, ...props }) => {

interface ThankYouProps {
  pageContext: { data: any };
}

interface PageContext {
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  title: string;
  content: string;
  acf: { seo: SEOProperties };
}

const ThankYou: React.FC<ThankYouProps> = ({ pageContext, ...props }) => {
  let pageContextData: PageContext = pageContext.data;
  return (
    <>
      <SEO seo={pageContextData.acf.seo} />
      <RespContainer>
        <Wrapper>
          <Image src={"images/thank-you.png"} alt={"/thank-you"} />
          <ThankYouText>
            Thank you for your submission! Our team will get back to you as soon
            as possible.
          </ThankYouText>

          <HelperLinksWrapper>
            <HelperText>Go to</HelperText>
            <EzReadMore to={"/"} customText={"Home Page"} />
          </HelperLinksWrapper>
        </Wrapper>
      </RespContainer>
    </>
  );
};

export default ThankYou;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 70px;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    margin-top: 116px;
    margin-bottom: 240px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    max-width: 520px;
  }
`;

const HelperLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;

  > a {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-weight: 600;
    font-size: 18px;
  }
`;

const HelperText = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`;

const ThankYouText = styled.p`
  font-size: 20px;

  margin-top: -5px;
  max-width: 310px;
  text-align: center;
  margin-bottom: 34px;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    font-size: 24px;
    max-width: 40.5rem;
    text-align: center;

    margin-top: -54px;
    margin-bottom: 22px;
  }
`;
