import { Link } from "gatsby";
import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { FontelloIcon } from "../../styles/CommonComponents";
import { Colors, Devices } from "../../styles/variables";

interface EzReadMoreTypes {
  to?: string;
  customText?: string;
}

const EzReadMore = ({ to, customText }: EzReadMoreTypes) => {
  const getContent = () => (
    <Fragment>
      <ReadMoreText>{customText || "Read More"}</ReadMoreText>
      <FontelloIcon icon={"ez-right"} />
    </Fragment>
  );
  return to !== undefined ? (
    <Fragment>
      <ReadMoreLinkable to={to}>{getContent()}</ReadMoreLinkable>
    </Fragment>
  ) : (
    <Fragment>
      <ReadMoreWithoutLink>{getContent()}</ReadMoreWithoutLink>
    </Fragment>
  );
};

export default EzReadMore;

const ReadMoreStyles = css`
  display: flex;
  color: ${Colors.sharpBlue};

  > span:first-child {
    border-bottom: 1px solid transparent;
  }

  &:hover > span:last-child {
    transform: translateX(30%);
  }
  > span:last-child {
    font-size: 12px !important;
    font-weight: 400 !important;
    padding-left: 9px;
    transform: translateX(0%);
    transition: transform ease 0.25s;
  }

  &:focus {
    > span:first-child {
      border-bottom: 1px solid ${Colors.sharpBlue};
    }
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.sharpBlue};
    text-decoration: none;
  }
`;

const ReadMoreLinkable = styled(Link)`
  ${ReadMoreStyles}
`;

const ReadMoreWithoutLink = styled("span")`
  ${ReadMoreStyles}
`;

const ReadMoreText = styled("span")``;
